<template>
	<div class="container">
		<div class="policyCompilationTitle">团队管理</div>
		<div class="top">
			<div>
				<el-button type="primary" icon="el-icon-plus" size="small" @click="addRoles">添加角色</el-button>
			</div>
		</div>
		<div class="table">
			<el-table :data="roles" border style="width: 100%">
				<el-table-column align="center" prop="title"  label="角色" width="160"></el-table-column>
				<el-table-column label="权限列表" align="center">
					<template slot-scope="scope">
						<el-tag size="small" effect="plain" v-for="item in scope.row.permission" :key="item.title" style="margin:5px">{{item.title}}</el-tag>
					</template>
				</el-table-column>
				<el-table-column align="center" label="操作" width="280">
					<template slot-scope="scope">
						<el-button type="primary" size="mini" @click="permissionsHandle(scope.$index,scope.row)">编辑权限</el-button>
						<el-button type="primary" size="mini" @click="editRoleHandle(scope.$index,scope.row)">编辑角色</el-button>
						<el-button type="danger" size="mini" @click="deleteHandele(scope.$index,scope.row)">删除</el-button>
					</template>
				</el-table-column>
			</el-table>
		</div>
	</div>
</template>

<script>
	export default {
		data() {
			return {
				keyword: '',
				roles: [],
				total: 0,
				page: 1,
				limit: 10,
				pageCount: Math.ceil(this.total / 10),
				orgin_id: ''
			}
		},
		mounted() {
			this.orgin_id = sessionStorage.organizationId;
			this.getRolesList();
		},
		methods: {
			getRolesList() {
				this.$http.get(this.URL.adminUrl.managersList + this.orgin_id + '/roles/permissions').then(res => {
					this.roles = res.data.data;
					this.total = res.data.data.length;
				})
			},
			//添加角色
			addRoles() {
				this.$prompt('输入角色名称', '添加角色').then(({
					value
				}) => {
					if(!value){
						this.$message({
							type: 'info',
							message: '角色名称不能为空 '
						});
						return
					}
					this.$http.post(this.URL.adminUrl.managersList + this.orgin_id + '/roles', {
						title: value
					}).then(res => {
						this.$http.get(this.URL.adminUrl.managersList + this.orgin_id + '/roles').then(res => {
							this.getRolesList();
						})
					}).catch(err=>{
						if(err.response.data.code == 400){
							this.$message.error(err.response.data.message);
						}
					})
				})
			},
			permissionsHandle(index, row) {
				this.$router.push({
					path: '/permissionsList',
					query: {
						id: row.id,
						title: row.title
					}
				})
			},
			//删除角色
			deleteHandele(index, row) {
				this.$confirm('确认删除？', '删除角色').then(() => {
					this.$http.delete(this.URL.adminUrl.managersList + this.orgin_id + '/roles/' + row.id).then(res => {
						this.roles.splice(index, 1);
						this.total-=1
						this.$message({
							type: 'success',
							message: '删除成功！'
						});
					})
				}).catch(() => {
					this.$message({
						type: 'info',
						message: '已取消删除'
					});
				})

			},
			//编辑角色
			editRoleHandle(index, row) {
				this.$prompt('角色名称', '编辑角色', {
					inputValue: this.roles[index].title
				}).then(({
					value
				}) => {
					this.$http.post(this.URL.adminUrl.managersList + this.orgin_id + '/roles/' + row.id, {
						title: value
					}).then(res => {
						this.roles[index].title = value
						this.$message({
							type: 'success',
							message: '更新成功'
						});
					})
				}).catch(() => {
					this.$message({
						type: 'info',
						message: '已取消更新'
					});
				})
			}

		}
	}
</script>

<style scoped>
	.top {
		display: flex;
		align-items: center;
		justify-content: flex-end;
	}

	.table {
		margin-top: 30px;
	}

	.pages {
		display: flex;
		align-items: center;
		justify-content: center;
		padding: 50px 0;
	}
</style>
